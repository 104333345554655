import type { MaterialTypeConfig } from './types';

export const ENUM_MATERIAL_TYPE: {[key in 'packing' | 'tag' | 'collarLabel' | 'praiseCard' | 'printing']: MaterialTypeConfig; } = {
  packing: {
    value: '3',
    label: '包装材料',
    showBelongTypeDesc: true,
    showAddAndEditAndDeleteBtn: true,
  },
  tag: {
    value: '1',
    label: '吊牌',
  },
  collarLabel: {
    value: '9',
    label: '领标',
  },
  praiseCard: {
    value: '5',
    label: '好评卡',
  },
  printing: {
    value: '7',
    label: '印花片',
  },
};

export const ENUM_MATERIAL_STATUS = {
  enabled: {
    value: '1',
    label: '已使用',
  },
  disabled: {
    value: '0',
    label: '未使用',
  },
};

export const ENUM_MATERIAL_BELONG_TYPE = {
  cloudWarehouse: {
    value: 1,
    label: '网仓包材',
  },
  owner: {
    value: 2,
    label: '货主包材',
  },
};
