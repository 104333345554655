import { Card, Layout, Menu } from 'antd';
import { MainSubStructure, NormalProgrammeComponent } from 'egenie-utils';
import { inject, observer, Provider } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import { Store } from './store';
import { ENUM_MATERIAL_TYPE } from './constants';
import { RenderByCondition } from 'egenie-common';

export default function() {
  const store = new Store();

  return (
    <Provider store={store}>
      <Layout className={styles.container}>
        <Layout.Sider
          theme="light"
          width={200}
        >
          <LeftCondition/>
        </Layout.Sider>
        <Layout.Content>
          <ContentWrapper/>
        </Layout.Content>
      </Layout>
    </Provider>
  );
}

@inject('store')
@observer
class ContentWrapper extends React.Component<{ store?: Store; }> {
  render() {
    const {
      activeMaterialType,
      packingStore,
      tagStore,
      praiseCardStore,
      printingStore,
      collarLabelStore,
    } = this.props.store;
    return (
      <>
        <RenderByCondition show={activeMaterialType === ENUM_MATERIAL_TYPE.packing.value}>
          <>
            <Card size="small">
              <NormalProgrammeComponent store={packingStore.normalProgramme}/>
            </Card>
            <div className={styles.tableWrapper}>
              <MainSubStructure store={packingStore.mainSubStructureModel}/>
            </div>
          </>
        </RenderByCondition>
        <RenderByCondition show={activeMaterialType === ENUM_MATERIAL_TYPE.tag.value}>
          <>
            <Card size="small">
              <NormalProgrammeComponent store={tagStore.normalProgramme}/>
            </Card>
            <div className={styles.tableWrapper}>
              <MainSubStructure store={tagStore.mainSubStructureModel}/>
            </div>
          </>
        </RenderByCondition>
        <RenderByCondition show={activeMaterialType === ENUM_MATERIAL_TYPE.praiseCard.value}>
          <>
            <Card size="small">
              <NormalProgrammeComponent store={praiseCardStore.normalProgramme}/>
            </Card>
            <div className={styles.tableWrapper}>
              <MainSubStructure store={praiseCardStore.mainSubStructureModel}/>
            </div>
          </>
        </RenderByCondition>
        <RenderByCondition show={activeMaterialType === ENUM_MATERIAL_TYPE.printing.value}>
          <>
            <Card size="small">
              <NormalProgrammeComponent store={printingStore.normalProgramme}/>
            </Card>
            <div className={styles.tableWrapper}>
              <MainSubStructure store={printingStore.mainSubStructureModel}/>
            </div>
          </>
        </RenderByCondition>
        <RenderByCondition show={activeMaterialType === ENUM_MATERIAL_TYPE.collarLabel.value}>
          <>
            <Card size="small">
              <NormalProgrammeComponent store={collarLabelStore.normalProgramme}/>
            </Card>
            <div className={styles.tableWrapper}>
              <MainSubStructure store={collarLabelStore.mainSubStructureModel}/>
            </div>
          </>
        </RenderByCondition>
      </>
    );
  }
}

@inject('store')
@observer
class LeftCondition extends React.Component<{ store?: Store; }> {
  render() {
    const {
      activeMaterialType,
      onSelect,
    } = this.props.store;
    return (
      <Menu
        mode="inline"
        onSelect={onSelect}
        selectedKeys={[activeMaterialType]}
      >
        <Menu.Item key={ENUM_MATERIAL_TYPE.packing.value}>
          {ENUM_MATERIAL_TYPE.packing.label}
        </Menu.Item>
        <Menu.Item key={ENUM_MATERIAL_TYPE.tag.value}>
          {ENUM_MATERIAL_TYPE.tag.label}
        </Menu.Item>
        <Menu.Item key={ENUM_MATERIAL_TYPE.praiseCard.value}>
          {ENUM_MATERIAL_TYPE.praiseCard.label}
        </Menu.Item>
        <Menu.Item key={ENUM_MATERIAL_TYPE.printing.value}>
          {ENUM_MATERIAL_TYPE.printing.label}
        </Menu.Item>
        <Menu.Item key={ENUM_MATERIAL_TYPE.collarLabel.value}>
          {ENUM_MATERIAL_TYPE.collarLabel.label}
        </Menu.Item>
      </Menu>
    );
  }
}

