import type { FormInstance } from 'antd';
import { Button, Col, Drawer, Form, Input, InputNumber, Row, Space, Upload } from 'antd';
import type { UploadFile } from 'antd/lib/upload/interface';
import { request } from 'egenie-common';
import React from 'react';
import { getDefaultFileList, uploadFileListToCdn, validateFileList } from '../../utils';
import type { MaterialItem, MaterialTypeConfig } from './types';

interface CreateMaterialModalProps {
  materialTypeConfig: MaterialTypeConfig;
  mainItem?: MaterialItem;
  callback?: (...arg: any[]) => any;
  onCancel?: (...arg: any[]) => any;
}

export const CreateMaterialModal: React.FC<CreateMaterialModalProps> = function({
  mainItem,
  materialTypeConfig,
  callback,
  onCancel,
}) {
  const formRef = React.useRef<FormInstance>();
  const [
    loading,
    setLoading,
  ] = React.useState<boolean>(false);
  const [
    fileList,
    setFileList,
  ] = React.useState<UploadFile[]>(getDefaultFileList(mainItem?.pic));

  async function handleFinish(params: MaterialItem) {
    setLoading(true);

    try {
      const pic = await uploadFileListToCdn({
        obsConfigId: 5,
        fileList,
      });

      await request({
        url: mainItem?.wmsValueAddedMaterialId ? '/api/cloud/baseinfo/rest/value/added/material/update' : '/api/cloud/baseinfo/rest/value/added/material/add',
        method: 'post',
        data: {
          ...params,
          pic: pic.join(''),
          wmsMaterialId: mainItem?.wmsValueAddedMaterialId,
          materialType: materialTypeConfig.value,
          length: params.length >>> 0,
          width: params.width >>> 0,
          height: params.height >>> 0,
          weight: params.weight >>> 0,
        },
      });

      if (typeof callback === 'function') {
        callback();
      }
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <Drawer
      footer={(
        <Row justify="end">
          <Space size="small">
            <Button onClick={onCancel}>
              取消
            </Button>
            <Button
              loading={loading}
              onClick={() => formRef.current.submit()}
              type="primary"
            >
              确定
            </Button>
          </Space>
        </Row>
      )}
      maskClosable={false}
      onClose={onCancel}
      title={`${mainItem?.wmsValueAddedMaterialId ? '编辑' : '创建'}${materialTypeConfig.label}`}
      visible
      width={600}
    >
      <Form
        initialValues={{
          weight: 0,
          length: 0,
          width: 0,
          height: 0,
          ...mainItem,
        }}
        labelCol={{ span: 4 }}
        layout="horizontal"
        onFinish={handleFinish}
        ref={formRef}
        wrapperCol={{ span: 20 }}
      >
        <Form.Item
          label={`${materialTypeConfig.label}名称`}
          name="materialName"
          rules={[
            {
              required: true,
              message: `请输入${materialTypeConfig.label}名称`,
            },
          ]}
        >
          <Input.TextArea
            allowClear
            autoSize={{
              minRows: 1,
              maxRows: 1,
            }}
            maxLength={20}
            placeholder={`请输入${materialTypeConfig.label}名称`}
            showCount
          />
        </Form.Item>
        <Row>
          <Col span={8}>
            <Form.Item
              label="长(cm)"
              labelCol={{ span: 12 }}
              name="length"
              rules={[
                {
                  required: true,
                  message: '请输入长度',
                },
              ]}
              wrapperCol={{ span: 12 }}
            >
              <InputNumber
                max={999999}
                min={0}
                placeholder="请输入长度"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="宽(cm)"
              labelCol={{ span: 12 }}
              name="width"
              rules={[
                {
                  required: true,
                  message: '请输入宽度',
                },
              ]}
              wrapperCol={{ span: 12 }}
            >
              <InputNumber
                max={999999}
                min={0}
                placeholder="请输入宽度"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="高(cm)"
              labelCol={{ span: 12 }}
              name="height"
              rules={[
                {
                  required: true,
                  message: '请输入高度',
                },
              ]}
              wrapperCol={{ span: 12 }}
            >
              <InputNumber
                max={999999}
                min={0}
                placeholder="请输入高度"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="重量(g)"
          labelCol={{ span: 4 }}
          name="weight"
          rules={[
            {
              required: true,
              message: '请输入重量',
            },
          ]}
          wrapperCol={{ span: 4 }}
        >
          <InputNumber
            max={999999}
            min={0}
            placeholder="请输入重量"
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          label="图片"
          name="pic"
          rules={[
            {
              required: true,
              validator: () => validateFileList({ fileList }),
            },
          ]}
        >
          <Upload

            // accept=".png,.jpg,.jpeg"
            beforeUpload={() => false}
            fileList={fileList}
            listType="picture-card"
            maxCount={1}
            onChange={(info) => setFileList([...info?.fileList].filter(Boolean))}
          >
            <div className="egenie-common-upload-container">
              <i className="icon-add"/>
              <footer>
                上传图片
              </footer>
            </div>
          </Upload>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
