import { ImgFormatter, MainSubStructureModel, NormalProgramme, Permission, request, TimeStampFormatter } from 'egenie-utils';
import React from 'react';
import { ENUM_MATERIAL_BELONG_TYPE, ENUM_MATERIAL_STATUS } from './constants';
import { Badge, Button, InputNumber, message, Modal } from 'antd';
import { Observer } from 'mobx-react';
import type { MaterialItem, MaterialTypeConfig } from './types';
import type { PaginationData } from 'egenie-common';
import { destroyAllModal, formatNumber, renderModal } from 'egenie-common';
import { CreateMaterialModal } from './createMaterialModal';
import { LogTooltip } from '../../utils';

let id = 0;

export class MaterialCommonStore {
  constructor(private readonly materialTypeConfig: MaterialTypeConfig) {
    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;
    this.mainSubStructureModel.onQuery();
  }

  public handleCreateOrEditMaterial = (mainItem?: MaterialItem) => {
    renderModal(
      <CreateMaterialModal
        callback={() => {
          destroyAllModal();
          if (mainItem?.wmsValueAddedMaterialId) {
            this.mainSubStructureModel.gridModel.onRefresh();
          } else {
            this.mainSubStructureModel.gridModel.onQuery();
          }
        }}
        mainItem={mainItem}
        materialTypeConfig={this.materialTypeConfig}
        onCancel={destroyAllModal}
      />
    );
  };

  public handleDeleteMaterial = (rows: MaterialItem[]) => {
    if (rows.length === 0) {
      const error = '请选择数据';
      message.warning({
        key: error,
        content: error,
      });
      return;
    }

    if (!rows.every((item) => item.belongType === ENUM_MATERIAL_BELONG_TYPE.cloudWarehouse.value && !item.enabled)) {
      const error = `只有${ ENUM_MATERIAL_BELONG_TYPE.cloudWarehouse.label}且${ENUM_MATERIAL_STATUS.disabled.label}能删除`;
      message.warning({
        key: error,
        content: error,
      });
      return;
    }

    Modal.confirm({
      centered: true,
      content: '确定删除吗?',
      onOk: async() => {
        await request({
          url: '/api/cloud/baseinfo/rest/value/added/material/delete',
          method: 'POST',
          data: { wmsMaterialIds: rows.map((item) => item.wmsValueAddedMaterialId) },
        })
          .then(() => {
            this.mainSubStructureModel.gridModel.clearToOriginal();
            this.mainSubStructureModel.gridModel.onRefresh();
            message.success('删除成功');
          });
      },
    });
  };

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          name: '操作',
          key: 'operate',
          width: 140,
          frozen: true,
          formatter: ({ row }: { row: MaterialItem; }) => {
            return (
              <>
                { this.materialTypeConfig.showAddAndEditAndDeleteBtn ? (
                  <>
                    <Permission permissionId="60160_44">
                      <Button
                        disabled={row.belongType === ENUM_MATERIAL_BELONG_TYPE.owner.value}
                        onClick={() => this.handleCreateOrEditMaterial(row)}
                        size="small"
                        type="link"
                      >
                        编辑
                      </Button>
                    </Permission>
                    <Permission permissionId="60160_45">
                      <Button
                        disabled={row.belongType === ENUM_MATERIAL_BELONG_TYPE.owner.value || row.enabled}
                        onClick={() => this.handleDeleteMaterial([row])}
                        size="small"
                        type="link"
                      >
                        删除
                      </Button>
                    </Permission>
                  </>
                ) : null }
                <Permission permissionId="60160_6055">
                  <LogTooltip
                    children={(
                      <Button
                        size="small"
                        type="link"
                      >
                        日志
                      </Button>
                    )}
                    entityId={row.wmsValueAddedMaterialId}
                    height={230}
                    logBizModuleCode={410000}
                    popoverProps={{
                      title: '日志',
                      trigger: 'click',
                    }}
                    width={800}
                  />
                </Permission>
              </>
            );
          },
        },
        {
          key: 'pic',
          name: '图片',
          width: 50,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          key: 'enabled',
          name: '使用状态',
          width: 80,
          formatter: ({ row }) => (
            row.enabled ? (
              <Badge
                status="success"
                text={ENUM_MATERIAL_STATUS.enabled.label}
              />
            ) : (
              <Badge
                status="default"
                text={ENUM_MATERIAL_STATUS.disabled.label}
              />
            )
          ),
        },
        {
          key: 'materialName',
          name: '名称',
          width: 200,
        },
        {
          key: 'spec',
          name: '规格',
          width: 120,
        },
        this.materialTypeConfig.showBelongTypeDesc ? {
          key: 'belongTypeDesc',
          name: '包材类型',
          width: 80,
        } : null,
        {
          key: 'ownerName',
          name: '货主',
          width: 200,
        },
        {
          key: 'principalOwnerName',
          name: '客户名称',
          width: 150,
        },
        {
          key: 'weight',
          name: '重量(g)',
          width: 100,
        },
        {
          key: 'cellNumber',
          name: '存储格子号',
          width: 120,
          formatter: ({
            rowIdx,
            row,
          }: { rowIdx: number; row: MaterialItem; }) => {
            return (
              <Observer>
                {
                  () => (
                    <InputNumber
                      max={99999}
                      min={0}
                      onBlur={() => {
                        request({
                          url: '/api/cloud/baseinfo/rest/value/added/material/cell/number',
                          method: 'post',
                          data: {
                            wmsMaterialId: row.wmsValueAddedMaterialId,
                            cellNumber: this.mainSubStructureModel.gridModel.rows[rowIdx].cellNumber,
                          },
                        })
                          .then(() => {
                            message.success({
                              key: '修改成功',
                              content: '修改成功',
                            });
                          });
                      }}
                      onChange={(value) => this.mainSubStructureModel.gridModel.rows[rowIdx].cellNumber = formatNumber(value)}
                      onClick={(event) => event.stopPropagation()}
                      placeholder="请输入"
                      value={this.mainSubStructureModel.gridModel.rows[rowIdx].cellNumber}
                    />
                  )
                }
              </Observer>
            );
          },
        },
        {
          key: 'createTime',
          name: '创建时间',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.createTime}/>,
        },
        {
          key: 'stockNum',
          name: '库存',
          width: 100,
        },
      ].filter(Boolean)
        .map((info) => ({
          resizable: true,
          sortable: false,
          ...info,
        })),
      rows: [],
      primaryKeyField: '_id',
      sortByLocal: false,
      showCheckBox: Boolean(this.materialTypeConfig.showAddAndEditAndDeleteBtn),
      showReset: true,
      showSelectedTotal: true,
      showEmpty: true,
      pageSize: 50,
    },
    pageId: '60160',
    buttons: this.materialTypeConfig.showAddAndEditAndDeleteBtn ? [
      {
        permissionId: '5020',
        text: `创建${this.materialTypeConfig.label}`,
        handleClick: () => this.handleCreateOrEditMaterial(),
      },
      {
        permissionId: '45',
        text: '批量删除',
        handleClick: () => this.handleDeleteMaterial(this.mainSubStructureModel.gridModel.selectRows),
      },
    ] : null,

    // 主表查询api
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        return request<PaginationData<MaterialItem>>({
          url: '/api/cloud/baseinfo/rest/value/added/material/page',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
            materialType: this.materialTypeConfig.value,
          },
        })
          .then((info) => ({
            status: 'Successful',
            data: {
              ...info.data,
              list: (info.data?.list || []).map((item) => ({
                ...item,
                _id: id++,
              })),
            },
          }));
      },
    },
    hiddenSubTable: true,
  });

  public normalProgramme: NormalProgramme = new NormalProgramme({
    count: 4,
    filterItems: [
      {
        type: 'input',
        field: 'materialName',
        label: `${this.materialTypeConfig.label}名称`,
      },
      {
        type: 'select',
        field: 'ownerIds',
        label: '货主',
        mode: 'multiple',
        isParamList: true,
      },
      {
        type: 'select',
        field: 'enabled',
        label: '使用状态',
        data: Object.values(ENUM_MATERIAL_STATUS),
      },
    ],
    handleSearch: this.mainSubStructureModel.gridModel.onQuery,
  });
}
