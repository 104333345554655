import { action, observable } from 'mobx';
import React from 'react';
import { MaterialCommonStore } from './materialCommonStore';
import { ENUM_MATERIAL_TYPE } from './constants';
import { getOwner } from '../../utils';

export class Store {
  constructor() {
    getOwner()
      .then((ownerIds) => {
        this.packingStore.normalProgramme.filterItems.addDict({ ownerIds });
        this.tagStore.normalProgramme.filterItems.addDict({ ownerIds });
        this.praiseCardStore.normalProgramme.filterItems.addDict({ ownerIds });
        this.printingStore.normalProgramme.filterItems.addDict({ ownerIds });
        this.collarLabelStore.normalProgramme.filterItems.addDict({ ownerIds });
      });
  }

  @observable public activeMaterialType: string = new URLSearchParams(location.search).get('activeMaterialType') || ENUM_MATERIAL_TYPE.packing.value;

  @action public onSelect = (item) => {
    this.activeMaterialType = item.key;

    if (this.activeMaterialType === ENUM_MATERIAL_TYPE.packing.value) {
      this.packingStore.mainSubStructureModel.onQuery();
    } else if (this.activeMaterialType === ENUM_MATERIAL_TYPE.tag.value) {
      this.tagStore.mainSubStructureModel.onQuery();
    } else if (this.activeMaterialType === ENUM_MATERIAL_TYPE.praiseCard.value) {
      this.praiseCardStore.mainSubStructureModel.onQuery();
    } else if (this.activeMaterialType === ENUM_MATERIAL_TYPE.printing.value) {
      this.printingStore.mainSubStructureModel.onQuery();
    } else if (this.activeMaterialType === ENUM_MATERIAL_TYPE.collarLabel.value) {
      this.collarLabelStore.mainSubStructureModel.onQuery();
    }
  };

  public packingStore: MaterialCommonStore = new MaterialCommonStore(ENUM_MATERIAL_TYPE.packing);

  public tagStore: MaterialCommonStore = new MaterialCommonStore(ENUM_MATERIAL_TYPE.tag);

  public praiseCardStore: MaterialCommonStore = new MaterialCommonStore(ENUM_MATERIAL_TYPE.praiseCard);

  public printingStore: MaterialCommonStore = new MaterialCommonStore(ENUM_MATERIAL_TYPE.printing);

  public collarLabelStore: MaterialCommonStore = new MaterialCommonStore(ENUM_MATERIAL_TYPE.collarLabel);
}

